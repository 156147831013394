import React,{useState,useEffect} from 'react';
import {Header} from '../../component/Header';
import {Footer} from '../../component/Footer';
import {Breadcrumb} from '../../component/Breadcrumb';
import {movie_detail_link, get_api_url_tag,get_api_url_pick_up_movie} from '../../utils/dmm_api';
import axios from 'axios';

export const MovieList = ()=>{
  let article = "";
  let article_id = "";
  let article_name = "";
  let article_kind = "";
  let movie_list_api;
  if (process.browser){
    article = document.getElementById('root').dataset.movieArticle; 
    article_id = document.getElementById('root').dataset.movieArticleId; 
    article_name = document.getElementById('root').dataset.movieArticleName; 
  }
  //リクエストパラメータがnullの場合はピックアップ動画一覧を表示する
  if ((article == "") && (article_id=="") && (article_name=="") ){
    movie_list_api = get_api_url_pick_up_movie(52);
  }else{
    movie_list_api = get_api_url_tag(article,article_id,article_name)
  }
  switch(article){
    case "actress":
      article_kind = "女優";
    break;
    case "director":
        article_kind = "ディレクター";
    break;
    case "genre":
        article_kind = "ジャンル";
    break;
    case "label":
        article_kind = "レーベル";
    break;
    case "maker":
        article_kind = "メーカー";
    break;
    default:
      article_kind = "ピックアップ";
    break;
  }
  console.log(`[movie_list_api] movie_list_apiの値 ${movie_list_api}`)
  const [movie_list,set_movie_list] = useState([])
  useEffect(()=>{
    axios.get(movie_list_api)
  // apiResultはweb APIの実行結果
    .then(api_result=>{
      set_movie_list(api_result.data.result.items)
    })
  },[])
  const breadcrumb_list = [{
      "name":"home",
      "link":"/",
    },
    {
      "name":`${article_kind} : ${article_name} 動画一覧ページ`,
    },
  ]
  return (
    <>
      <Header />
      <div className="page page-movie-list">
        <div className="page-main">
          <div className="page-content">
            <Breadcrumb BreadcrumbList={breadcrumb_list} />
            <h1 className="page-title">{article_kind}:{article_name} 動画一覧</h1>
            <div className="movie-list">
                {
                  movie_list.map(
                    movie_list => 
                    <div className="movie-list__item"  key={movie_list.content_id}>
                      <a href={movie_detail_link(movie_list.content_id)}>
                        <img src={movie_list.imageURL.large} alt="" />
                        <div className="movie-list__item-name">{movie_list.title}</div>
                        <div className="movie-list__item-actress">{}</div>
                      </a>
                    </div>
                  )
                }
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </>
  );
}

