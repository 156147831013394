
import React,{useState,useEffect} from 'react';
import {Header} from '../../component/Header';
import {Footer} from '../../component/Footer';
import {Breadcrumb} from '../../component/Breadcrumb';
import {movie_detail_link, get_api_url_tag} from '../../utils/dmm_api';
import axios from 'axios';

export const ActressDetail1039982 = ()=>{
  let article_id = "1039982";
  let article_name = "美谷朱里";
  let article_name_en = "Mitani Akari";
  let movie_list_api;
  movie_list_api = get_api_url_tag("actress",article_id,"")

  const [movie_list,set_movie_list] = useState([])
  useEffect(()=>{
    axios.get(movie_list_api)
    .then(api_result=>{
      set_movie_list(api_result.data.result.items)
    })
  },[])

  let breadcrumb_list = [{
      "name":"home",
      "link":"/",
    },{
      "name":"人気AV女優一覧",
      "link":"/actress/list",
    },
    {
      "name":`${article_name}`,
    },
]

  return (
    <>
      <Header />
      <div className="page page-actress-detail">
        <div className="page-main">
          <div className="page-content">
            <Breadcrumb BreadcrumbList={breadcrumb_list} />
            <div className="actress-detail">
              <div className="actress-detail__contents">
                <div className="actress-detail__thumbnail">
                  <img src="https://pics.dmm.co.jp/digital/video/dasd00743/dasd00743jp-10.jpg" alt="家庭に入り落ち着いたように見せる美谷朱里" /> 
                </div>
                <h1 className="page-title" >
                  {article_name} <span>{article_name_en}</span>
                </h1>
                <p className="actress-detail__description">
                  スカパー!アダルト放送大賞2019新人賞、 FANZAアダルトアワード2019特別賞した今をときめくAV女優の美谷朱里。
                  きらびやかな世界にいるはずなのに、いい意味でそんなオーラを出さない、一般人にいそうな感じがエロを引き立てる。
                  ごくごく普通の女子大学生といった印象。しかし作品を見てみるとさすがトップ女優だなと思わせてくれる。エロ&美しい&可愛いが同時に攻めてくる。
                  妖艶で表情が素晴らしい。スタイル抜群で何をとってもいい。彼女が今後のAVギョウカイを引っ張っていくことは間違いない。
                </p>
                <div className="actress-detail__profile">
                  <dl className="actress-detail__definition-list">
                    <dt className="actress-detail__definition-trem">
                      生年月日
                    </dt>
                    <dd className="actress-detail__definition-description">
                      1997/4/15
                    </dd>
                    <dt className="actress-detail__definition-trem">
                      出身
                    </dt>
                    <dd className="actress-detail__definition-description">
                      神奈川県
                    </dd>
                    <dt className="actress-detail__definition-trem">
                      デビュー
                    </dt>
                    <dd className="actress-detail__definition-description">
                      2017/07/01
                    </dd>
                    <dt className="actress-detail__definition-trem">
                      サイズ
                    </dt>
                    <dd className="actress-detail__definition-description">
                      B:83/W:58/H:86(cm)
                    </dd>
                    <dt className="actress-detail__definition-trem">
                      趣味
                    </dt>
                    <dd className="actress-detail__definition-description">
                      <ul>
                        <li>パズドラ</li>
                        <li>鬼滅の刃</li>
                        <li>進撃の巨人</li>
                        <li>BTS</li>
                      </ul>
                    </dd>
                    <dt className="actress-detail__definition-trem">
                      関連タグ
                    </dt>
                    <dd className="actress-detail__definition-description">
                      <div className="movie-tag">
                        <ul class="movie-tag__list">
                          <li class="movie-tag__list-item" data-element-id="1027">
                            <a href="/movie/list?article=genre&amp;article_id=1027&amp;article_name=美少女">美少女</a>
                          </li>
                          <li class="movie-tag__list-item" data-element-id="1027">
                            <a href="/movie/list?article=genre&amp;article_id=1027&amp;article_name=巨乳">巨乳</a>
                          </li>
                        </ul>
                      </div>
                    </dd>
                    <dt className="actress-detail__definition-trem">
                      ギャラリー
                    </dt>
                    <dd className="actress-detail__definition-description">
                        <div className='actress-detail__gallery-list'>
                          <div className='actress-detail__gallery-list-item'>
                            <img src='https://pbs.twimg.com/media/FH8dakaVUAAEL99?format=jpg&name=large' alt='' />
                            <img src='https://pics.dmm.co.jp/digital/video/wanz00802/wanz00802jp-2.jpg' alt="" />
                            <img src='https://pics.dmm.co.jp/digital/video/wanz00802/wanz00802jp-9.jpg' alt="" />
                            <img src='https://pics.dmm.co.jp/digital/video/hnvr00055/hnvr00055jp-2.jpg' alt="" />
                            <img src='https://pics.dmm.co.jp/digital/video/hnvr00055/hnvr00055jp-7.jpg' alt="" />
                          </div>
                        </div>
                    </dd>

                    <dt className="actress-detail__definition-trem">
                      {article_name} の関連動画
                    </dt>
                    <dd className="actress-detail__definition-description">
                      <div className="movie-list">
                          {
                            movie_list.map(
                              movie_list => 
                              <div className="movie-list__item"  key={movie_list.content_id}>
                                <a href={movie_detail_link(movie_list.content_id)}>
                                  <img src={movie_list.imageURL.large} alt={movie_list.title} />
                                  <div className="movie-list__item-name">{movie_list.title}</div>
                                  <div className="movie-list__item-actress">{}</div>
                                </a>
                              </div>
                            )
                          }
                      </div>
                    </dd>
                  </dl>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </>
  );
}

