import React from 'react';

export const Header =()=> {
    return(
        <>
          <header className="header">
            <div className="header__inner">
                <div className="header__log">
                  <a href="/" className="linker">
                    <img src="/img/common/logo.png" alt="header logo" />
                  </a>
                </div>
                <div className="header__nav">
                  <div className="header__nav-item">
                    <a href="/" className="linker">top</a>
                  </div>
                  <div className="header__nav-item">
                    <a href="/terms" className="linker">利用規約</a>
                  </div>
                  <div className="header__nav-item">
                    <a href="/movie/list" className="linker">動画一覧</a>
                  </div>
                        <div className="header__nav-item">
                            <a href="/actress/list" className="linker">人気AV女優一覧</a>
                        </div>
                  {/* <div className="header__nav-item">
                    <div className="header__search">
                      <form action="">
                        <input type="search" />
                        <button>検索</button>
                      </form>
                    </div>
                  </div> */}
                </div>
            </div>
          </header>
        </>
    )
};