import React from 'react';

export const MovieTag =()=> {
    return(
          <div class="movie-tag">
            <ul class="movie-tag__list">
              <li class="movie-tag__list-item" data-element-id="26762">
                <a href="/movie/list?article=actress&amp;article_id=26762&amp;article_name=横山美雪">横山美雪</a>
              </li>
              <li class="movie-tag__list-item" data-element-id="6006">
                <a href="/movie/list?article=genre&amp;article_id=6006&amp;article_name=デビュー作品">デビュー作品</a>
              </li>
              <li class="movie-tag__list-item" data-element-id="1057330">
                <a href="/movie/list?article=actress&amp;article_id=1057330&amp;article_name=安齋らら">安齋らら</a>
              </li>
              <li class="movie-tag__list-item" data-element-id="1054998">
                <a href="/movie/list?article=actress&amp;article_id=1054998&amp;article_name=松本いちか">松本いちか</a>
              </li>
              <li class="movie-tag__list-item" data-element-id="6012">
                <a href="/movie/list?article=genre&amp;article_id=6012&amp;article_name=4時間以上作品">4時間以上作品</a>
              </li>
              <li class="movie-tag__list-item" data-element-id="6533">
                <a href="/movie/list?article=genre&amp;article_id=6533&amp;article_name=ハイビジョン">ハイビジョン</a>
              </li>
              <li class="movie-tag__list-item" data-element-id="79015">
                <a href="/movie/list?article=genre&amp;article_id=79015&amp;article_name=4K">4K</a>
              </li>
              <li class="movie-tag__list-item" data-element-id="6548">
                <a href="/movie/list?article=genre&amp;article_id=6548&amp;article_name=独占配信">独占配信</a>
                </li>
                <li class="movie-tag__list-item" data-element-id="1027">
                  <a href="/movie/list?article=genre&amp;article_id=1027&amp;article_name=美少女">美少女</a>
                </li>
                <li class="movie-tag__list-item" data-element-id="4025">
                  <a href="/movie/list?article=genre&amp;article_id=4025&amp;article_name=単体作品">単体作品</a>
                </li>
                <li class="movie-tag__list-item" data-element-id="3006">
                  <a href="/movie/list?article=genre&amp;article_id=3006&amp;article_name=パンスト・タイツ">パンスト・タイツ</a>
                </li>
                <li class="movie-tag__list-item" data-element-id="5004">
                  <a href="/movie/list?article=genre&amp;article_id=5004&amp;article_name=手コキ">手コキ</a>
                </li>
                <li class="movie-tag__list-item" data-element-id="4059">
                  <a href="/movie/list?article=genre&amp;article_id=4059&amp;article_name=キス・接吻">キス・接吻</a>
                </li>
                <li class="movie-tag__list-item" data-element-id="6017">
                  <a href="/movie/list?article=genre&amp;article_id=6017&amp;article_name=ギリモザ">ギリモザ</a>
                </li>
                <li class="movie-tag__list-item" data-element-id="5002">
                  <a href="/movie/list?article=genre&amp;article_id=5002&amp;article_name=フェラ">フェラ</a>
                </li>
                <li class="movie-tag__list-item" data-element-id="1071049">
                  <a href="/movie/list?article=actress&amp;article_id=1071049&amp;article_name=冨安れおな">冨安れおな</a>
                </li>
              </ul>
            </div>
    )
};