import React from 'react';
import {Header} from '../component/Header';
import {Footer} from '../component/Footer';
import {Breadcrumb} from '../component/Breadcrumb';

export const Terms = ()=>{
  let breadcrumb_list = [{
      "name":"home",
      "link":"/",
    },
    {
      "name":"利用規約",
    },
  ];
  return (
    <>
      <Header />
      <div className="page page-terms">
        <div className="page-main">
          <div className="page-content">
            <Breadcrumb BreadcrumbList={breadcrumb_list} />
            <h1 className="page-title">利用規約</h1>
            <div className="terms">
              <p>この利用規約（以下，「本規約」といいます。）は，ぷにじょ.com（以下，「当サイト」といいます。）が提供するサービス（以下，「本サービス」といいます。）の利用条件を定めるものです。サイト利用ユーザーの皆さま（以下，「ユーザー」といいます。）には，本規約に従って，本サービスをご利用いただきます。</p>
              <div className="terms__box">
                <h2 className="terms__title">第1条（適用）</h2>
                <ol>
                  <li>本規約は，ユーザーと当サイトとの間の本サービスの利用に関わる一切の関係に適用されるものとします。</li>
                  <li>当サイトは本サービスに関し，本規約のほか，ご利用にあたってのルール等，各種の定め（以下，「個別規定」といいます。）をすることがあります。これら個別規定はその名称のいかんに関わらず，本規約の一部を構成するものとします。</li>
                  <li>本規約の規定が前条の個別規定の規定と矛盾する場合には，個別規定において特段の定めなき限り，個別規定の規定が優先されるものとします。</li>
                </ol>
              </div>
              <div className="terms__box">
                <h2 className="terms__title">第2条（サイト閲覧）</h2>
                <ol>
                  <li>当サイトはアダルト、ポルノを性的画像、動画、言動、音声を含むコンテンツを扱っており当サイトへのアクセスは成人(18歳以上に達している個人)だけを対象にサービスを提供します。</li>
                  <li>成人年齢に達していない個人、当サイトの表示を望まない成人が当サイトにアクセス、閲覧することを一切禁止します。</li>
                </ol>
              </div>
              <div className="terms__box">
                <h2 className="terms__title">第3条（禁止事項）</h2>
                <p>サービス上のコンテンツは、私的かつ非商業目的で、ユーザーの娯楽のためだけに使用されるものであり、その他を目的とした使用は一切禁止します。<br />ユーザーは，本サービスの利用にあたり，以下の行為をしてはなりません。</p>
                <ol>
                  <li>法令または公序良俗に違反する行為</li>
                  <li>犯罪行為に関連する行為</li>
                  <li>本サービスの内容等，本サービスに含まれる著作権，商標権ほか知的財産権を侵害する行為</li>
                  <li>当サイト、ほかのユーザー、またはその他第三者のサーバーまたはネットワークの機能を破壊したり，妨害したりする行為</li>
                  <li>本サービスによって得られた情報を商業的に利用する行為</li>
                  <li>当サイトのサービスの運営を妨害するおそれのある行為</li>
                  <li>不正アクセスをし，またはこれを試みる行為</li>
                  <li>他のユーザーに関する個人情報等を収集または蓄積する行為</li>
                  <li>不正な目的を持って本サービスを利用する行為</li>
                  <li>本サービスの他のユーザーまたはその他の第三者に不利益，損害，不快感を与える行為</li>
                  <li>他のユーザーに成りすます行為</li>
                  <li>当サイトが許諾しない本サービス上での宣伝，広告，勧誘，または営業行為</li>
                  <li>面識のない異性との出会いを目的とした行為</li>
                  <li>当サイトのサービスに関連して，反社会的勢力に対して直接または間接に利益を供与する行為</li>
                  <li>SNSによる当サイトおよび誹謗中傷行為</li>
                  <li>当サイトが不適切と判断する行為</li>
                </ol>
              </div>
              <div className="terms__box">
                <h2 className="terms__title">第4条（本サービスの提供の停止等）</h2>
                <ol>
                  <li>
                    <p>
                      当サイトは，以下のいずれかの事由があると判断した場合，ユーザーに事前に通知することなく本サービスの全部または一部の提供を停止または中断することができるものとします。
                    </p>
                    <ol>
                      <li>本サービスにかかるコンピュータシステムの保守点検または更新を行う場合</li>
                      <li>地震，落雷，火災，停電または天災などの不可抗力により，本サービスの提供が困難となった場合</li>
                      <li>コンピュータまたは通信回線等が事故により停止した場合</li>
                      <li>その他，当社が本サービスの提供が困難と判断した場合</li>
                    </ol>
                    </li>
                  <li>当サイトの提供の停止または中断により，ユーザーまたは第三者が被ったいかなる不利益または損害についても，一切の責任を負わないものとします。</li>
                </ol>
              </div>
              <div className="terms__box">
                <h2 className="terms__title">第5条（保証の否認および免責事項）</h2>
                <ol>
                  <li>当サイトは，本サービスに事実上または法律上の瑕疵（安全性，信頼性，正確性，完全性，有効性，特定の目的への適合性，セキュリティなどに関する欠陥，エラーやバグ，権利侵害などを含みます。）がないことを明示的にも黙示的にも保証しておりません。</li>
                  <li>当サイトは，本サービスに起因してユーザーに生じたあらゆる損害について一切の責任を負いません。ただし，本サービスに関する当社とユーザーとの間の契約（本規約を含みます。）が消費者契約法に定める消費者契約となる場合，この免責規定は適用されません。</li>
                  <li>前項ただし書に定める場合であっても，当サイトは，当サイトの過失（重過失を除きます。）による債務不履行または不法行為によりユーザーに生じた損害のうち特別な事情から生じた損害（当社またはユーザーが損害発生につき予見し，または予見し得た場合を含みます。）について一切の責任を負いません。また，当社の過失（重過失を除きます。）による債務不履行または不法行為によりユーザーに生じた損害の賠償は，ユーザーから当該損害が発生した月に受領した利用料の額を上限とします。</li>
                  <li>当サイトは，本サービスに関して，ユーザーと他のユーザーまたは第三者との間において生じた取引，連絡または紛争等について一切責任を負いません。</li>
                </ol>
              </div>
              <div className="terms__box">
                <h2 className="terms__title">第6条（サービス内容の変更等）</h2>
                <ol>
                  <li>当サイトは，ユーザーに通知することなく，本サービスの内容を変更しまたは本サービスの提供を中止することができるものとし，これによってユーザーに生じた損害について一切の責任を負いません。</li>
                </ol>
              </div>
              <div className="terms__box">
                <h2 className="terms__title">第8条（利用規約の変更）</h2>
                <p>当サイトは，必要と判断した場合には，ユーザーに通知することなくいつでも本規約を変更することができるものとします。なお，本規約の変更後，本サービスの利用を開始した場合には，当該ユーザーは変更後の規約に同意したものとみなします。</p>
              </div>
              <div className="terms__box">
                <h2 className="terms__title">第8条（権利義務の譲渡の禁止）</h2>
                <p>ユーザーは，当社の書面による事前の承諾なく，利用契約上の地位または本規約に基づく権利もしくは義務を第三者に譲渡し，または担保に供することはできません。</p>
              </div>
              <div className="terms__box">
                <h2 className="terms__title">第9条（準拠法・裁判管轄）</h2>
                <ol>
                  <li>本規約の解釈にあたっては，日本法を準拠法とします。</li>
                  <li>本サービスに関して紛争が生じた場合には，当サイトを管轄する裁判所を専属的合意管轄とします。</li>
                </ol>
              </div>
              <p>以上</p>
            </div>
          </div>
        </div>

      </div>
      <Footer/>
    </>
  );
}

