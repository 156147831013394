
import React,{useState,useEffect} from 'react';
import {Header} from '../../component/Header';
import {Footer} from '../../component/Footer';
import {Breadcrumb} from '../../component/Breadcrumb';
import {movie_detail_link, get_api_url_tag} from '../../utils/dmm_api';
import axios from 'axios';

export const ActressDetail28135 = ()=>{
  let article_id = "28135";
  let movie_list_api;
  movie_list_api = get_api_url_tag("actress",article_id,"")

  const [movie_list,set_movie_list] = useState([])
  useEffect(()=>{
    axios.get(movie_list_api)
    .then(api_result=>{
      set_movie_list(api_result.data.result.items)
    })
  },[])

  let breadcrumb_list = [{
      "name":"home",
      "link":"/",
    },{
      "name":"人気AV女優一覧",
      "link":"/actress/list",
    },
    {
      "name":"成瀬心美",
    },
]

  return (
    <>
      <Header />
      <div className="page page-actress-detail">
        <div className="page-main">
          <div className="page-content">
            <Breadcrumb BreadcrumbList={breadcrumb_list} />
            <div className="actress-detail">
              <div className="actress-detail__contents">
                <div className="actress-detail__thumbnail">
                  <img src="https://pics.dmm.co.jp/digital/video/49mgdv00018/49mgdv00018jp-1.jpg" alt="ボールペンを渡してくれている成瀬心美" /> 
                </div>
                <h1 className="page-title" >
                  成瀬心美 <span>Kokomi Naruse</span>
                </h1>
                <p className="actress-detail__description">
                  「スカパー！アダルト放送大賞」2012年度の女優賞を得るほどの人気、美貌、プロポーションを持ったAV女優の成瀬心美様々な役をこなせるカメレオンAV女優。彼女の演技力からドラマのような見応えがあり起承転結がしっかりとした作品が多い。アダルトを引退しても現在はタレント、バンド活動を行っている。
                </p>
                <div className="actress-detail__profile">
                  <dl className="actress-detail__definition-list">
                    <dt className="actress-detail__definition-trem">
                      生年月日
                    </dt>
                    <dd className="actress-detail__definition-description">
                      1989/8/10
                    </dd>
                    <dt className="actress-detail__definition-trem">
                      出身
                    </dt>
                    <dd className="actress-detail__definition-description">
                      新潟県
                    </dd>
                    <dt className="actress-detail__definition-trem">
                      デビュー
                    </dt>
                    <dd className="actress-detail__definition-description">
                      2009/1/1
                    </dd>
                    <dt className="actress-detail__definition-trem">
                      サイズ
                    </dt>
                    <dd className="actress-detail__definition-description">
                      B:89/W:60/H:82(cm)
                    </dd>
                    <dt className="actress-detail__definition-trem">
                      趣味
                    </dt>
                    <dd className="actress-detail__definition-description">
                      <ul>
                        <li>音楽</li>
                        <li>カラオケ</li>
                      </ul>
                    </dd>
                    <dt className="actress-detail__definition-trem">
                      関連タグ
                    </dt>
                    <dd className="actress-detail__definition-description">
                      <div className="movie-tag">
                      
                        <ul class="movie-tag__list">
                          <li class="movie-tag__list-item" data-element-id="1027">
                            <a href="/movie/list?article=genre&amp;article_id=1027&amp;article_name=スレンダー">スレンダー</a>
                          </li>
                          <li class="movie-tag__list-item" data-element-id="1027">
                            <a href="/movie/list?article=genre&amp;article_id=1027&amp;article_name=巨乳">巨乳</a>
                          </li>
                          <li class="movie-tag__list-item" data-element-id="1027">
                            <a href="/movie/list?article=genre&amp;article_id=1027&amp;article_name=美女">美女</a>
                          </li>
                        </ul>
                      </div>
                    </dd>
                    <dt className="actress-detail__definition-trem">
                      関連動画
                    </dt>
                    <dd className="actress-detail__definition-description">
                      <div className="movie-list">
                          {
                            movie_list.map(
                              movie_list => 
                              <div className="movie-list__item"  key={movie_list.content_id}>
                                <a href={movie_detail_link(movie_list.content_id)}>
                                  <img src={movie_list.imageURL.large} alt={movie_list.title} />
                                  <div className="movie-list__item-name">{movie_list.title}</div>
                                  <div className="movie-list__item-actress">{}</div>
                                </a>
                              </div>
                            )
                          }
                      </div>
                    </dd>
                  </dl>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </>
  );
}

