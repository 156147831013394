import React from 'react';
import {Header} from '../component/Header';
import {Footer} from '../component/Footer';
import {Sidebar} from '../component/Sidebar';
import {Breadcrumb} from '../component/Breadcrumb';
import {SITE_NAME} from '../utils/const';

export const NoMatch = ()=>{
  let breadcrumb_list = [{
      "name":"home",
      "link":"/",
    },
    {
      "name":"404ページ",
    },
]
  return (
    <>
      <Header />
      <div className="page page-404">
        <div className="page-main">
          <Sidebar />
          <div className="page-content">
            <Breadcrumb BreadcrumbList={breadcrumb_list} />
            <h1 className="page-title">404ページ</h1>
            <p>{SITE_NAME}にお越しいただきありがとうござます。このページは存在しません。</p>
            {/*------ おすすめの作品とか表示させる ------*/}
              <a className="button" href="/">TOPページへ</a>
          </div>
        </div>
      </div>
      <Footer/>
    </>
  );
}

