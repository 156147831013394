import React from 'react';
import { Header } from '../component/Header';
import { Footer } from '../component/Footer';
import { Sidebar } from '../component/Sidebar';
import { PickupMovieList } from '../component/PickupMovieList';
import { SITE_NAME } from '../utils/const';
import { ActressListComponent } from '../component/ActressList';
import { MovieTag } from '../component/MovieTag';

export const Top = () => {
  return (
    <>
      <Header />
      <div className="page page-top">
        <h1 className="site-heading">
          <span>エロ動画まとめサイト「{SITE_NAME} 」最新のエロをお届け</span>
        </h1>
        <p>このサイトはエロ動画を探しやすくしたポータルサイトです。AV女優のリンクまたは各種タグのリンクのタグそれぞれのページにアクセスし、エロ動画を閲覧ください。<br/>またサイトオーナーおすすめのエロ動画のブログ記事を載せています。</p>
        {/* 
          page-mainをdisplay flexとして
          page-content、Sidebarを子要素とする
         */}
        <div className="page-main">
          <div className="page-content">
            <section className="section">
              <div className="pickup-movie">
                <h2 className="section-title">ピックアップ動画</h2>
                <PickupMovieList hits="16" />
                <a href="/movie/list" className="button button--more">
                  もっと見る
                </a>
              </div>
            </section>
            <section className="section">
              <div className="actress">
                <h2 className="section-title">人気AV女優一覧</h2>
                <ActressListComponent />
                <a href="/actress/list" className="button button--more">
                  もっと見る
                </a>
              </div>
            </section>
            <section className="section">
              <div className="blog">
                <h2 className="section-title">記事一覧</h2>
                <div className="blog__archive">
                  <div className="blog__archive-list">
                    <div className="blog__archive-list-item">
                      <a href="/blog/article/001">
                        <img src="/img/top/blog_thumbnail1.png" alt="「ど田舎の夏はヤルことがなくて隣の美人奥さんの誘惑に乗っかり毎日じっとり汗だく交尾 葵つかさ」を見た感想サムネイル" />
                        <div className="blog__archive-body">
                          <h3 className="blog__archive-title">「ど田舎の夏はヤルことがなくて隣の美人奥さんの誘惑に乗っかり毎日じっとり汗だく交尾 葵つかさ」を見た感想</h3>
                          <p>葵つかささんが出演されている「ど田舎の○○はヤルことがなくて」シリーズの作品です。
                            こちらを試聴した感想は、「畳でセックスがしたくなった」です。...(本文へ)</p>
                          <div className="blog__time">2021.08.01</div>
                        </div>
                      </a>
                    </div>
                    <div className="blog__archive-list-item">
                      <a href="/blog/article/002">
                        <img src="/img/top/blog_thumbnail2.png" alt="エスワン15周年スペシャル大共演 第3弾 超豪華S1女優大集合 素人チ●ポをヌキまくりハメまくり夢の大乱交！ ファン大感謝祭ツアー の感想のサムネイル" />
                        <div className="blog__archive-body">
                          <h3 className="blog__archive-title">エスワン15周年スペシャル大共演 第3弾 超豪華S1女優大集合 素人チ●ポをヌキまくりハメまくり夢の大乱交！ ファン大感謝祭ツアー の感想</h3>
                          <p>三上悠亜、あやみ旬果、葵つかさ、吉高寧々、橋本ありな、 羽咲みはる、 天使もえという誰もが知るビックネームの女優が勢揃いした奇跡の作品です。...(本文へ)</p>
                          <div className="blog__time">2021.08.18</div>
                        </div>
                      </a>
                    </div>
                    <div className="blog__archive-list-item">
                      <a href="/blog/article/003">
                        <img src="/img/top/blog_thumbnail3.png" alt="羽咲みはる４周年メモリアルベストのサムネイル" />
                        <div className="blog__archive-body">
                          <h3 className="blog__archive-title">羽咲みはる4周年メモリアルベスト 最新全15タイトル72コーナー480分スペシャルをみた感想【すごく良かった】</h3>
                          <p>可愛さ、美しさありの巨乳でロリな羽咲みはるの4周年メモリアルベスト盤です...(本文へ)</p>
                          <div className="blog__time">2021.12.22</div>
                        </div>
                      </a>
                    </div>
                    <div className="blog__archive-list-item">
                      <a href="/blog/article/004">
                        <img src="https://pics.dmm.co.jp/digital/video/mide00975/mide00975jp-2.jpg" alt="【待ちに待った】『解禁 高橋しょう子 初めての中出し性交』をみた感想" />
                        <div className="blog__archive-body">
                          <h3 className="blog__archive-title">【待ちに待った】『解禁 高橋しょう子 初めての中出し性交』をみた感想</h3>
                          <p>高橋しょう子が遂に中出しを解禁してくれました!彼女のデビュー時の衝撃と同じくらいの衝撃を受けました!この作品を観なければ高橋しょう子は語れない!</p>
                          <div className="blog__time">2022.4.11</div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className="section">
              <div className="manager">
                <h2 className="section-title">コンセプト</h2>
                <div className="manager__introduction">
                  <div className="manager__overview">
                    <p>町のレンタルビデオ屋さんにあるAVコーナーのような雰囲気がこのサイトのコンセプト。誰でもスマホやPCでエロが手に入れらる時代だからこそローカルな、あの街のレンタルビデオ屋さんのAVコーナーの近寄り難いけど親しみがある、ついつい暖簾を潜りたくなるそんなサイトを目指しています。</p>
                  </div>
                </div>
              </div>
            </section>
            <section className="section">
              <div className="tag">
                <h2 className="section-title">おすすめタグ</h2>
                <MovieTag />
              </div>
            </section>

          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
