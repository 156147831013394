import React,{useState,useEffect} from 'react';
import axios from 'axios';
import {movie_detail_link,api_url_new_arrival_movie,affiliate_link_digital_video_detail,get_api_url_relation_movie} from '../utils/dmm_api'

export const DetailRelation =(Props)=> {
  const actress_name_list = Props.actress_name_list;
  console.log(`actress_name_list:${actress_name_list}`);
  const [detail_relation,set_detail_relation] = useState([])
  useEffect(()=>{
    axios.get(get_api_url_relation_movie(actress_name_list))
    .then(api_result=>{
      set_detail_relation(api_result.data.result.items)
    })
  },[])
    return(
        <>
          <div className="movie-detail__relation">
            <h2 className="movie-detail__title">関連動画</h2>
            <div className="movie-detail__relation-list">
              {
                detail_relation.map(
                  detail_relation => 
                  <div className="movie-detail__relation-list-item" key={detail_relation.content_id}>
                    <a href={movie_detail_link(detail_relation.content_id)}>
                      <div className="movie-detail__relation-list-item-thumbnail">
                        <img src={detail_relation.imageURL.large} alt="" />
                      </div> 
                      <div className="movie-detail__relation-list-item-name">{detail_relation.title}</div>
                    </a>
                  </div>
                )
              }
            </div>
          </div>
        </>
    )
};