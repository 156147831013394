import React from 'react';

export const ActressListComponent =()=> {
    return(
        <>
          <div className="actress">
            <div className="actress__list">
              <div className="actress__list-item">
                <a href="/actress/1030262">
                  <img src="https://pics.dmm.co.jp/mono/actjpgs/mikami_yua.jpg" alt="三上悠亜" />
                  <div className="actress__list-item-name">三上悠亜</div>
                </a>
              </div>
              <div className="actress__list-item">
                <a href="/actress/1012844">
                  <img src="https://pics.dmm.co.jp/mono/actjpgs/sakura_mana.jpg" alt="紗倉まな" />
                  <div className="actress__list-item-name">紗倉まな</div>
                </a>
              </div>
              <div className="actress__list-item">
                <a href="/actress/28135">
                  <img src="https://pics.dmm.co.jp/mono/actjpgs/naruse_kokomi.jpg" alt="成瀬心美" />
                  <div className="actress__list-item-name">成瀬心美</div>
                </a>
              </div>
              <div className="actress__list-item">
                <a href="/actress/1039982">
                  <img src="https://pics.dmm.co.jp/mono/actjpgs/mitani_akari.jpg" alt="美谷朱里" />
                  <div className="actress__list-item-name">美谷朱里</div>
                </a>
              </div>
            </div>
          </div>
        </>
    )
};