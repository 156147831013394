import React from 'react';
import {BrowserRouter,Route,Switch} from 'react-router-dom';
import {Top} from './pages/Top';
import {Terms} from './pages/Terms';
import {BlogArticle1} from './pages/blog/BlogArticle1';
import {BlogArticle2} from './pages/blog/BlogArticle2';
import {BlogArticle3} from './pages/blog/BlogArticle3';
import {BlogArticle4} from './pages/blog/BlogArticle4';
import {NoMatch} from './pages/NoMatch';
import {ActressList} from './pages/actress/List';
import {ActressDetail1030262} from './pages/actress/Detail_1030262';
import {ActressDetail1012844} from './pages/actress/Detail_1012844';
import {ActressDetail28135} from './pages/actress/Detail_28135';
import {ActressDetail1039982} from './pages/actress/Detail_1039982';
import {MovieList} from './pages/movie/List';
import {MovieDetail} from './pages/movie/Detail';

//URLはローティングを使うのではなくて、propsでpagesを渡せばいいのでは?
export const Router = ()=>{
  return (
    <>
    <BrowserRouter>
        <Switch>
            <Route path='/' component={Top} exact />
            <Route path='/terms' component={Terms} exact />
            <Route path='/blog/article/001' component={BlogArticle1} exact />
            <Route path='/blog/article/002' component={BlogArticle2} exact />
            <Route path='/blog/article/003' component={BlogArticle3} exact />
            <Route path='/blog/article/004' component={BlogArticle4} exact />
            <Route path='/actress/list' component={ActressList} exact />
            <Route path='/actress/1030262' component={ActressDetail1030262} exact />
            <Route path='/actress/1012844' component={ActressDetail1012844} exact />
            <Route path='/actress/28135' component={ActressDetail28135} exact />
            <Route path='/actress/1039982' component={ActressDetail1039982} exact />
            <Route path='/movie/list' component={MovieList} exact />
            <Route path='/movie/detail/:pageId' component={MovieDetail} exact />
            <Route path="*" component={NoMatch} exact />
        </Switch>
    </BrowserRouter>
  </>
  );
}