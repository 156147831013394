import React from 'react';
import { Header } from '../../component/Header';
import { Footer } from '../../component/Footer';
import { Sidebar } from '../../component/Sidebar';
import { Breadcrumb } from '../../component/Breadcrumb';
import { affiliate_link_digital_video_detail } from '../../utils/dmm_api';

export const BlogArticle4 = ()=>{
  const affiliate_link = affiliate_link_digital_video_detail("mide00975");
  console.log(affiliate_link)
  const breadcrumb_list = [{
      "name":"home",
      "link":"/",
    },
    {
      "name":"【待ちに待った】『解禁 高橋しょう子 初めての中出し性交』をみた感想",
    },
]
  return (
    <>
      <Header />
      <div className="page page-blog-article">
        <div className="page-main">
          <Sidebar />
          <div className="page-content">
            <Breadcrumb BreadcrumbList={breadcrumb_list} />
            <h1 className="page-title">【待ちに待った】『解禁 高橋しょう子 初めての中出し性交』をみた感想</h1>
              <div className="blog-article">
                <div className="blog-article__time">
                  <time>2022.4.11</time>
                </div>
                <div className="blog-article__main-visual">
                  <img src="https://pics.dmm.co.jp/digital/video/mide00975/mide00975jp-2.jpg" alt=""/>
                </div>
                <div className="blog-article__box">
                    <p>
                      リンク<br />
                      <a href={affiliate_link} target="_blank">解禁 高橋しょう子 初めての中出し性交</a>
                    </p>
                    <p className="blog-article__beginning">ずっとこの時を待っていました。ようやく解禁してくれた高橋しょう子の中出し性交。</p>
                    <h2>パーフェクトAV女優</h2>
                    <p>彼女はグラビアアイドル出身だけあってスタイルや表情使いが抜群にうまい。</p>

                  <a href={affiliate_link} target="_blank"  className="button button--orange button--buy ">
                    動画を視聴する
                  </a>
                  <p className="blog-article__beginning">
                    高橋しょう子が遂に中出しを解禁してくれました!彼女のデビュー時の衝撃と同じくらいの衝撃を受けました!この作品を観なければ高橋しょう子は語れない!
                  </p>
                  
                  <h2>より色っぽく</h2>
                  <p>今回最初に感じたのは、デビュー後まもない頃と比べてより色っぽくなり、セクシーさが増したかなと感じた。垢抜けさがいい感じにエロを感じさせて、男の脳汁をドバドバにしてくれる。</p>
                  
                  <h2>中出し</h2>
                  <p>本当に中出ししているのか?していないのか?と見る前は誰しも思うだろう。しかしそんな真偽はどうでも良くなっている。そこにあるのは中出しをしている(されている)高橋しょう子の姿のみである。これは揺るぎない自室。画面越しにあるのは、中出し姿の高橋しょう子。こんなに最高なことがあるだろうか。生きていてよかったとつくづく思う。 </p>

                  <h2></h2>
                  <p></p>


                  <div className="blog-article__image_list">
                    <div className="blog-article__image_list-item">
                      <img src="https://pics.dmm.co.jp/digital/video/mide00975/mide00975jp-1.jpg" alt="バックでハメられる羽咲みはる"/>
                    </div>
                    <div className="blog-article__image_list-item">
                      <img src="https://pics.dmm.co.jp/digital/video/mide00975/mide00975jp-2.jpg" alt="ホテルで中年親父にテーブルで正常位しながら舌でキスをする羽咲みはる"/>
                    </div>
                    <div className="blog-article__image_list-item">
                      <img src="https://pics.dmm.co.jp/digital/video/mide00975/mide00975jp-3.jpg" alt="コインランドリーで全裸でセックスをする羽咲みはる"/>
                    </div>
                    <div className="blog-article__image_list-item">
                      <img src="https://pics.dmm.co.jp/digital/video/mide00975/mide00975jp-4.jpg" alt="青年とバックでセックスをする羽咲みはる"/>
                    </div>
                    <div className="blog-article__image_list-item">
                      <img src="https://pics.dmm.co.jp/digital/video/mide00975/mide00975jp-5.jpg" alt="亀甲縛りで大洪水な羽作みはる"/>
                    </div>
                    <div className="blog-article__image_list-item">
                      <img src="https://pics.dmm.co.jp/digital/video/mide00975/mide00975jp-6.jpg" alt="倉庫で体の大きな男性とバックでセックスする羽咲みはる" />
                    </div>
                    <div className="blog-article__image_list-item">
                      <img src="https://pics.dmm.co.jp/digital/video/mide00975/mide00975jp-7.jpg" alt="中年男性にフェラをしてあげる羽咲みはる"/>
                    </div>
                    <div className="blog-article__image_list-item">
                      <img src="https://pics.dmm.co.jp/digital/video/mide00975/mide00975jp-8.jpg" alt="はめられながら両側の男性におっぱいを揉まれる羽咲みはる"/>
                    </div>
                    <div className="blog-article__image_list-item">
                      <img src="https://pics.dmm.co.jp/digital/video/mide00975/mide00975jp-9.jpg" alt="ホテルのシャワーでドアに押しつけられながらセックスをする羽咲みはる"/>
                    </div>
                    <div className="blog-article__image_list-item">
                      <img src="https://pics.dmm.co.jp/digital/video/mide00975/mide00975jp-10.jpg" alt="男性が射精した精子を優しく口で受け止める羽咲みはる"/>
                    </div>
                    <div className="blog-article__image_list-item">
                      <img src="https://pics.dmm.co.jp/digital/video/mide00975/mide00975jp-11.jpg" alt="男性が射精した精子を優しく口で受け止める羽咲みはる"/>
                    </div>
                    <div className="blog-article__image_list-item">
                      <img src="https://pics.dmm.co.jp/digital/video/mide00975/mide00975jp-12.jpg" alt="男性が射精した精子を優しく口で受け止める羽咲みはる"/>
                    </div>
                  </div>
                  <a href={affiliate_link} target="_blank"  className="button button--orange button--buy">
                    動画を視聴する
                  </a>
                </div>
              </div>
          </div>
        </div>

      </div>
      <Footer/>
    </>
  );
}

