import React,{useState,useEffect} from 'react';
import axios from 'axios';
import {movie_detail_link, get_api_url_pick_up_movie} from '../utils/dmm_api'

export const PickupMovieList =(props)=> {
  const [pick_movies,set_pick_movie] = useState([])
  let api_url_pick_up_movie=get_api_url_pick_up_movie(28);
  useEffect(()=>{
    axios.get(api_url_pick_up_movie)
    // apiResultはweb APIの実行結果
    .then(api_result=>{
      set_pick_movie(api_result.data.result.items)
    })
  },[])

  return(
      <>
        <div className="movie-list movie-list--pickup">
          {
            pick_movies.map(
              pick_movie => 
              <div className="movie-list__item" key={pick_movie.content_id}>
                <a href={movie_detail_link(pick_movie.content_id)}>
                  <img src={pick_movie.imageURL.large} alt="" />
                  <div className="movie-list__item-name">{pick_movie.title}</div>
                  <div className="movie-list__item-actress">{}</div>
                </a>
              </div>
            )
          }
        </div>
      </>
  )
};