import React from 'react';
import {Header} from '../../component/Header';
import {Footer} from '../../component/Footer';
import {Breadcrumb} from '../../component/Breadcrumb';
import {ActressListComponent} from '../../component/ActressList';

export const ActressList = ()=>{
  let breadcrumb_list = [{
      "name":"home",
      "link":"/",
    },
    {
      "name":"女優一覧",
    },
]

  return (
    <>
      <Header />
      <div className="page page-actress-list">
        <div className="page-main">
          <div className="page-content">
            <Breadcrumb BreadcrumbList={breadcrumb_list} />
            <h1 className="page-title" >人気AV女優一覧</h1>
            <ActressListComponent />
          </div>
        </div>
      </div>
      <Footer/>
    </>
  );
}

