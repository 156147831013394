import React from 'react';
import { Header } from '../../component/Header';
import { Footer } from '../../component/Footer';
import { Sidebar } from '../../component/Sidebar';
import { Breadcrumb } from '../../component/Breadcrumb';
import { affiliate_link_digital_video_detail } from '../../utils/dmm_api';

export const BlogArticle3 = ()=>{
  const affiliate_link = affiliate_link_digital_video_detail("ofje00271");
  const breadcrumb_list = [{
      "name":"home",
      "link":"/",
    },
    {
      "name":"羽咲みはる4周年メモリアルベスト 最新全15タイトル72コーナー480分スペシャルをみた感想【すごく良かった】",
    },
]
  return (
    <>
      <Header />
      <div className="page page-blog-article">
        <div className="page-main">
          <Sidebar />
          <div className="page-content">
            <Breadcrumb BreadcrumbList={breadcrumb_list} />
            <h1 className="page-title">羽咲みはる4周年メモリアルベスト 最新全15タイトル72コーナー480分スペシャルをみた感想【すごく良かった】</h1>
              <div className="blog-article">
                <div className="blog-article__time">
                  <time>2021.08.11</time>
                </div>

                <div className="blog-article__main-visual">
                  <img src="/img/top/blog_thumbnail3.png" alt="羽咲みはる4周年メモリアルベスト 最新全15タイトル72コーナー480分スペシャルの感想サムネイル"/>
                </div>
                <div className="blog-article__box">
                    <p>
                      リンク<br />
                      <a href={affiliate_link} target="_blank">羽咲みはる4周年メモリアルベスト 最新全15タイトル72コーナー480分スペシャル</a>
                    </p>
                    <p className="blog-article__beginning">可愛さ、美しさありの巨乳でロリな羽咲みはるの4周年メモリアルベスト盤です</p>
                    <h2>過去作品の総まとめ</h2>
                    <p>過去のよりすぐりの作品を集めたメモリアルベストです。とっても可愛いですし、巨乳なので羽咲みはるさんのことがドストライクな人は多いのではないのでしょうか?喋り方や声のトーンなども音て落ち着いていてとっても素敵な方です。スタイルも抜群で文句なしの逸材だと思っています。<br/>過去作はどれもハズレがなくあたりばかりです。</p>

                    <h2>大満足の480分</h2>
                    <p>480分と長尺なので、チャプターごとに楽しむのもありかなと個人的には思います。ストーリー性のあるものは監督、脚本の方が知恵を絞ったいい作品ばかりだなと思い、エロ目的以外にも物語としとして楽しむのもありかなと思っています。</p>

                    <h2>エロいんだけど。。</h2>
                    <p>すごくエロいんだけどどこか恥じらいがあるところが魅力的な羽咲みはるさん。そんなことを改めて認識させていただきました。</p>

                    <h2>演技力抜群</h2>
                    <p>演技力があるため、どんな設定でも引き込まれる。正直AV女優という枠に拘らず映画やドラマなどにもどんどんでても問題ない逸材だと個人的に思っている。ただNTRとかは流石に興奮する。</p>

                    <h2>この作品を機に</h2>
                    <p>この作品を機にベスト盤ではない作品に手を出すのもいいかと思いました。(所々手を加えらている?と思うカットがあるため。。)<br/>では良き羽咲みはるライフを!!!!!</p>

                  <a href={affiliate_link} target="_blank"  className="button button--orange button--buy ">
                    動画を視聴する
                  </a>
                  <div className="blog-article__image_list">
                    <div className="blog-article__image_list-item">
                      <img src="https://pics.dmm.co.jp/digital/video/ofje00271/ofje00271jp-1.jpg" alt="バックでハメられる羽咲みはる"/>
                    </div>
                    <div className="blog-article__image_list-item">
                      <img src="https://pics.dmm.co.jp/digital/video/ofje00271/ofje00271jp-2.jpg" alt="ホテルで中年親父にテーブルで正常位しながら舌でキスをする羽咲みはる"/>
                    </div>
                    <div className="blog-article__image_list-item">
                      <img src="https://pics.dmm.co.jp/digital/video/ofje00271/ofje00271jp-3.jpg" alt="コインランドリーで全裸でセックスをする羽咲みはる"/>
                    </div>
                    <div className="blog-article__image_list-item">
                      <img src="https://pics.dmm.co.jp/digital/video/ofje00271/ofje00271jp-4.jpg" alt="青年とバックでセックスをする羽咲みはる"/>
                    </div>
                    <div className="blog-article__image_list-item">
                      <img src="https://pics.dmm.co.jp/digital/video/ofje00271/ofje00271jp-5.jpg" alt="亀甲縛りで大洪水な羽作みはる"/>
                    </div>
                    <div className="blog-article__image_list-item">
                      <img src="https://pics.dmm.co.jp/digital/video/ofje00271/ofje00271jp-6.jpg" alt="倉庫で体の大きな男性とバックでセックスする羽咲みはる" />
                    </div>
                    <div className="blog-article__image_list-item">
                      <img src="https://pics.dmm.co.jp/digital/video/ofje00271/ofje00271jp-7.jpg" alt="中年男性にフェラをしてあげる羽咲みはる"/>
                    </div>
                    <div className="blog-article__image_list-item">
                      <img src="https://pics.dmm.co.jp/digital/video/ofje00271/ofje00271jp-8.jpg" alt="はめられながら両側の男性におっぱいを揉まれる羽咲みはる"/>
                    </div>
                    <div className="blog-article__image_list-item">
                      <img src="https://pics.dmm.co.jp/digital/video/ofje00271/ofje00271jp-9.jpg" alt="ホテルのシャワーでドアに押しつけられながらセックスをする羽咲みはる"/>
                    </div>
                    <div className="blog-article__image_list-item">
                      <img src="https://pics.dmm.co.jp/digital/video/ofje00271/ofje00271jp-10.jpg" alt="男性が射精した精子を優しく口で受け止める羽咲みはる"/>
                    </div>
                  </div>
                  <a href={affiliate_link} target="_blank"  className="button button--orange button--buy">
                    動画を視聴する
                  </a>
                </div>
              </div>
          </div>
        </div>

      </div>
      <Footer/>
    </>
  );
}

