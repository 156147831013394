import React from 'react';
import { Header } from '../../component/Header';
import { Footer } from '../../component/Footer';
import { Sidebar } from '../../component/Sidebar';
import { Breadcrumb } from '../../component/Breadcrumb';
import { affiliate_link_digital_video_detail } from '../../utils/dmm_api';

export const BlogArticle1 = ()=>{
  const affiliate_link = affiliate_link_digital_video_detail("ssni00987");
  const breadcrumb_list = [{
      "name":"home",
      "link":"/",
    },
    {
      "name":"「ど田舎の夏はヤルことがなくて隣の美人奥さんの誘惑に乗っかり毎日じっとり汗だく交尾 葵つかさ」を見た感想",
    },
]
  return (
    <>
      <Header />
      <div className="page page-blog-article">
      <div className="page-main">
          <Sidebar />
          <div className="page-content">
            <Breadcrumb BreadcrumbList={breadcrumb_list} />
            <h1 className="page-title" >「ど田舎の夏はヤルことがなくて隣の美人奥さんの誘惑に乗っかり毎日じっとり汗だく交尾 葵つかさ」を見た感想</h1>
            
              <div className="blog-article">
                <div className="blog-article__time">
                  <time>2021.08.01</time>
                </div>
                <div className="blog-article__main-visual">
                  <img src="/img/top/blog_thumbnail1.png" alt="「ど田舎の夏はヤルことがなくて隣の美人奥さんの誘惑に乗っかり毎日じっとり汗だく交尾 葵つかさ」を見た感想サムネイル"/>
                </div>
                <div className="blog-article__box">
                  <p>リンク<br /><a href={affiliate_link} target="_blank">ど田舎の夏はヤルことがなくて隣の美人奥さんの誘惑に乗っかり毎日じっとり汗だく交尾 葵つかさ</a></p>
                  <p className="blog-article__beginning">葵つかささんが出演されている「ど田舎の○○はヤルことがなくて」シリーズの作品です。
                  こちらを試聴した感想は、「畳でセックスがしたくなった」です。
                  これまでも「ど田舎の○○はヤルことがなくて」シリーズは何作品か拝見しましたが、畳に目がいかずというか「田舎」というワードが出てくるものの、あまり田舎を感じることなくシンプルにエロを楽しむだけとなってしまいがちな私ですが、この作品はシンプルに畳いいなと思えました。
                  人妻好きにはたまならない作品となっています。
                  誘惑方法もたまらなくエッチでこんな美人で可愛らしい人が隣にいたらね。。そりゃしょうがないよと思います。
                  今回のこの作品は、葵つかささんの何か満たされない感じの人妻と出てくる田舎がマッチしていました。旦那がいるのに。。。とか思いつつ、しなければいけない感じも出てて余計エロをそそる作品となっていました。</p>
                  <h2>畳のよさ</h2>
                  <p>冒頭でも書きましたが、このシリーズは何作品も見ていましたしなんなら好きなシリーズの一つでもあります。<br />ですが畳に目がいかずというか、全然意識していなかったのですが、この作品で致す際に使われる「畳」はとても性的に魅力が溢れてて、葵つかささんのエロを惹き立たせる役割を全うしているなという気持ちになりました。<br />ひと昔まえのエッチな映画とかストーリはめちゃくちゃなのに致すときの描写はなんか印象的なんですよね。ひょっとしたら畳効果かもしれません(?)</p>
                  <h2>誘惑</h2>
                  <p>積極的なんだけどどこか静かというか。優しい誘惑だなと思いました。これで誘惑に乗らない人いるのってくらい魅力的な誘惑でした。。</p>
                  <h2>まとめ</h2>
                  <p>夏の真昼間から、田舎の家で畳でセックスがしたくなる作品でした。<br />コロナ落ち着いたら田舎に行こうと思いました(結局何もないw)<br />ではまたお気に入りの作品が出たら記事を書いていこうと思います。<br />管理人</p>
                  <a href={affiliate_link} target="_blank"  className="button button--orange button--buy ">
                    動画を視聴する
                  </a>
                  <div className="blog-article__image_list">
                    <div className="blog-article__image_list-item">
                      <img src="https://pics.dmm.co.jp/digital/video/ssni00987/ssni00987jp-1.jpg" alt="雑巾掛けをする葵つかさをイヤらしい目で見る青年"/>
                    </div>
                    <div className="blog-article__image_list-item">
                      <img src="https://pics.dmm.co.jp/digital/video/ssni00987/ssni00987jp-2.jpg" alt="青年をエロくおっぱいを出しながら誘惑する葵つかさ"/>
                    </div>
                    <div className="blog-article__image_list-item">
                      <img src="https://pics.dmm.co.jp/digital/video/ssni00987/ssni00987jp-3.jpg" alt="葵つかさのおっぱいを揉む青年"/>
                    </div>
                    <div className="blog-article__image_list-item">
                      <img src="https://pics.dmm.co.jp/digital/video/ssni00987/ssni00987jp-4.jpg" alt="タバコを吸うおじさんの裏でディープキスをする葵つかさと青年"/>
                    </div>
                    <div className="blog-article__image_list-item">
                      <img src="https://pics.dmm.co.jp/digital/video/ssni00987/ssni00987jp-5.jpg" alt="座布団の上で葵つかさに濃厚なクンニする青年"/>
                    </div>
                    <div className="blog-article__image_list-item">
                      <img src="https://pics.dmm.co.jp/digital/video/ssni00987/ssni00987jp-6.jpg" alt="葵つかさに後ろから激しく突く青年" />
                    </div>
                    <div className="blog-article__image_list-item">
                      <img src="https://pics.dmm.co.jp/digital/video/ssni00987/ssni00987jp-7.jpg" alt="SEXしている葵つかさ"/>
                    </div>
                    <div className="blog-article__image_list-item">
                      <img src="https://pics.dmm.co.jp/digital/video/ssni00987/ssni00987jp-8.jpg" alt="横でおじさんが寝ているのに激しくSEXをする青年と葵つかさ"/>
                    </div>
                    <div className="blog-article__image_list-item">
                      <img src="https://pics.dmm.co.jp/digital/video/ssni00987/ssni00987jp-9.jpg" alt="お風呂で葵つかさに手コキをされる青年"/>
                    </div>
                    <div className="blog-article__image_list-item">
                      <img src="https://pics.dmm.co.jp/digital/video/ssni00987/ssni00987jp-10.jpg" alt="お風呂で葵つかさとSEXをする青年"/>
                    </div>
                  </div>
                  <a href={affiliate_link} target="_blank"  className="button button--orange button--buy">
                    動画を視聴する
                  </a>
                </div>
              </div>
          </div>
        </div>

      </div>
      <Footer/>
    </>
  );
}

