import React from 'react';

// import logo from '../img/common/logo.png';
export const Footer = () => {
    return(
        <>
        <footer className="footer">
            <div className="footer__inner">
                <div className="footer__column">
                    <div className="footer__log">
                        <a to="/" className="linker">
                            <img src="/img/common/logo.png" alt="footer logo" />
                        </a>
                    </div>
                    <div className="footer__nav">
                        <div className="footer__nav-item">
                            <a href="/" className="linker">top</a>
                        </div>
                        <div className="footer__nav-item">
                            <a href="/terms" className="linker">利用規約</a>
                        </div>
                        <div className="footer__nav-item">
                            <a href="/movie/list" className="linker">動画一覧</a>
                        </div>
                        <div className="footer__nav-item">
                            <a href="/actress/list" className="linker">人気AV女優一覧</a>
                        </div>
                    </div>
                </div>
                <div className="footer__credit">
                    <a href="https://affiliate.dmm.com/api/"><img src="https://p.dmm.co.jp/p/affiliate/web_service/r18_88_35.gif" width="88" height="35" alt="WEB SERVICE BY FANZA" /></a>
                </div>
                <div className="footer__copylight">
                    <small>copylight</small>
                </div>
            </div>
            
        </footer>
        </>
    )
}


