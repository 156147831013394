import React,{useState,useEffect} from 'react';
import axios from 'axios';
import {movie_detail_link,api_url_new_arrival_movie,affiliate_link_digital_video_detail} from '../utils/dmm_api'

export const Sidebar =()=> {
  const [new_arrival,set_new_arrival] = useState([])
  useEffect(()=>{
    axios.get(api_url_new_arrival_movie)
  // apiResultはweb APIの実行結果
    .then(api_result=>{
      set_new_arrival(api_result.data.result.items)
    })
  },[])
    return(
        <>
        <div className="sidebar">
          <div className="sidebar__new_arrival">
            {
              new_arrival.map(
                pick_movie => 
                <div className="sidebar__new_arrival-item" key={pick_movie.content_id}>
                  <a href={movie_detail_link(pick_movie.content_id)}>
                    <img src={pick_movie.imageURL.large} alt="" />
                    <div className="sidebar__new_arrival-item-name">{pick_movie.title}</div>
                  </a>
                </div>
              )
            }
          </div>
          
        </div>
        </>
    )
};