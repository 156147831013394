
import React,{useState,useEffect} from 'react';
import {Header} from '../../component/Header';
import {Footer} from '../../component/Footer';
import {Breadcrumb} from '../../component/Breadcrumb';
import {movie_detail_link, get_api_url_tag} from '../../utils/dmm_api';
import axios from 'axios';

export const ActressDetail1012844 = ()=>{
  let article_id = "1012844";
  let movie_list_api;
  movie_list_api = get_api_url_tag("actress",article_id,"")

  const [movie_list,set_movie_list] = useState([])
  useEffect(()=>{
    axios.get(movie_list_api)
    .then(api_result=>{
      set_movie_list(api_result.data.result.items)
    })
  },[])

  let breadcrumb_list = [{
      "name":"home",
      "link":"/",
    },{
      "name":"人気AV女優一覧",
      "link":"/actress/list",
    },
    {
      "name":"紗倉まな",
    },
]

  return (
    <>
      <Header />
      <div className="page page-actress-detail">
        <div className="page-main">
          <div className="page-content">
            <Breadcrumb BreadcrumbList={breadcrumb_list} />
            <div className="actress-detail">
              <div className="actress-detail__contents">
                <div className="actress-detail__thumbnail">
                  <img src="https://pics.dmm.co.jp/digital/video/1stars00415/1stars00415jp-3.jpg" alt="胸元を強調している紗倉まな" /> 
                </div>
                <h1 className="page-title" >
                  紗倉まな <span>Mana Sakura</span>
                </h1>
                <p className="actress-detail__description">
                  出演作品数150本以上。デビューから10年近く経った今でもロリコン度は100%。かつプラスしてセクシーさも兼ね備えている万能型のAV女優です。ハードな作品からコアな作品まで幅広く活躍している。AV女優以外でもタレント、小説家という一面もありマルチに活躍中。また自身のYouTubeチャンネルを持っていおり、YouTuberとしての一面もある。笑顔がとても素敵でスタイル、性格共に120点。男性なら彼女に一度は恋したことがあると思う。
                </p>
                <div className="actress-detail__profile">
                  <dl className="actress-detail__definition-list">
                    <dt className="actress-detail__definition-trem">
                      生年月日
                    </dt>
                    <dd className="actress-detail__definition-description">
                      1993/3/23
                    </dd>
                    <dt className="actress-detail__definition-trem">
                      出身
                    </dt>
                    <dd className="actress-detail__definition-description">
                      千葉県
                    </dd>
                    <dt className="actress-detail__definition-trem">
                      デビュー
                    </dt>
                    <dd className="actress-detail__definition-description">
                      2011/11/1
                    </dd>
                    <dt className="actress-detail__definition-trem">
                      サイズ
                    </dt>
                    <dd className="actress-detail__definition-description">
                      B:89/W:58/H:89(cm)
                    </dd>
                    <dt className="actress-detail__definition-trem">
                      趣味
                    </dt>
                    <dd className="actress-detail__definition-description">
                      <ul>
                        <li>読書</li>
                        <li>コラム書き</li>
                        <li>映画鑑賞</li>
                        <li>測量</li>
                      </ul>
                    </dd>
                    <dt className="actress-detail__definition-trem">
                      関連タグ
                    </dt>
                    <dd className="actress-detail__definition-description">
                      <div className="movie-tag">
                      
                        <ul class="movie-tag__list">
                          <li class="movie-tag__list-item" data-element-id="1027">
                            <a href="/movie/list?article=genre&amp;article_id=1027&amp;article_name=美少女">美少女</a>
                          </li>
                          <li class="movie-tag__list-item" data-element-id="1027">
                            <a href="/movie/list?article=genre&amp;article_id=1027&amp;article_name=巨乳">巨乳</a>
                          </li>
                          <li class="movie-tag__list-item" data-element-id="1027">
                            <a href="/movie/list?article=genre&amp;article_id=1027&amp;article_name=ロリコン">ロリコン</a>
                          </li>
                        </ul>
                      </div>
                    </dd>
                    <dt className="actress-detail__definition-trem">
                      関連動画
                    </dt>
                    <dd className="actress-detail__definition-description">
                      <div className="movie-list">
                          {
                            movie_list.map(
                              movie_list => 
                              <div className="movie-list__item"  key={movie_list.content_id}>
                                <a href={movie_detail_link(movie_list.content_id)}>
                                  <img src={movie_list.imageURL.large} alt={movie_list.title} />
                                  <div className="movie-list__item-name">{movie_list.title}</div>
                                  <div className="movie-list__item-actress">{}</div>
                                </a>
                              </div>
                            )
                          }
                      </div>
                    </dd>
                  </dl>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </>
  );
}

