import React,{useState,useEffect} from 'react';
import {Header} from '../../component/Header';
import {Footer} from '../../component/Footer';
import {DetailRelation} from '../../component/DetailRelation';
import {PickupMovieList} from '../../component/PickupMovieList';
import {Breadcrumb} from '../../component/Breadcrumb';
// import {SITE_NAME} from '../../utils/const';
// import useReactRouter from 'use-react-router';
import {affiliate_link_digital_video_detail,get_api_url_detail_movie} from '../../utils/dmm_api'
import axios from 'axios';

export const MovieDetail = ()=>{
  let content_id=""
  if (process.browser){
    content_id = document.getElementById('root').dataset.contentId;
  }
  const api_url_detail_movie = get_api_url_detail_movie(content_id)
  const [detail_movie,set_detail_movie] = useState([])
  useEffect(()=>{ 
    axios.get(api_url_detail_movie)
      .then(api_result=>{
      set_detail_movie(api_result.data.result.items)

    })
  },[])
  const breadcrumb_list = [{
      "name":"home",
      "link":"/",
    },
    {
      "name":"動画詳細ページ",
    },
  ]

//商品のタグ確認あればタグを返しなければからのdivを返す
const detail_movie_tag_element =(element_value,tag_name,tag_kind)=>{
  let tag_item;
  let tag_element;
    try {
      tag_item = 
        element_value.map(
          element_value =>
            <li className="movie-tag__list-item" data-element-id={element_value.id}>
              <a href={`/movie/list?article=${tag_kind}&article_id=${element_value.id}&article_name=${element_value.name}`}>
                {element_value.name}
              </a>
              </li>
        )
      tag_element = 
        <div className="movie-detail__movie-tag">
          <div className="movie-tag">
            <div className="movie-tag-name">{tag_name}</div>
            <ul className="movie-tag__list">
                {tag_item}
            </ul>
          </div>
        </div>
    }catch (error) {
      tag_element = <div className="movie-detail__movie-tag"></div>
    }
    return tag_element
  }
const detail_movie_sample_movie_element = (element_value) =>{
  let movie_element;
  try{
    movie_element = 
      <div className="movie-detail__main_visual">
        <iframe src={element_value.sampleMovieURL.size_720_480} scrolling="no" frameborder="0" allowfullscreen></iframe>
      </div>
    //動画が取得できない場合は、動画のメインビジュアルを入れる
  }catch (error) {
    console.log(`error:${error}`)
      movie_element = 
      <div className="movie-detail__main_visual">
        <img src={element_value.imageURL.large} alt="" />
      </div>
    }
    return movie_element
}

const detail_movie_sampleImageURL_sample_l_image_element = (element_value) =>{
  let image_item_element;
  let image_element;
  try{
    image_item_element = 
      element_value.sampleImageURL.sample_l.image.map(
        detail_movie_sampleImageURL_sample_l_image=>
          <div className="movie-detail__image-list-item">
            <img src={detail_movie_sampleImageURL_sample_l_image} alt="" />
          </div>
      )
    image_element = 
      <div className="movie-detail__image">
        <h2 className="movie-detail__title">サンプル画像</h2>
        <div className="movie-detail__image-list">
          {image_item_element}
        </div>
      </div>
    //動画が取得できない場合は、動画のメインビジュアルを入れる
  }catch (error) {
    console.log(`error:${error}`)
      image_element = 
          <div className="movie-detail__image"></div>
    }

    return image_element
}

//商品のタグ確認あればタグを返しなければからのdivを返す
const component_detail_relation = (element_value)=>{
  let actress_name_list;
    try {
      actress_name_list = 
        element_value.map(
          element_value =>
            element_value.name
            // <DetailRelation actress_id={taset} />
        )
    }catch (error) {
      actress_name_list = null
    }
    return <DetailRelation actress_name_list={actress_name_list} /> 
  }

  //HTMLに描画するDOM
  return (
    <>
      <Header />
      <div className="page page-movie-detail">
        <div className="page-main">
          <div className="page-content">
            <div className="movie-detail">
                <Breadcrumb BreadcrumbList={breadcrumb_list} />
          {
            detail_movie.map(
              detail_movie => 
              <div key={detail_movie.content_id}>
                <div className="movie-detail__main">
                  <div className="movie-detail__content" >
                    <h1 className="page-title">{detail_movie.title}</h1>
                    <div className="movie-detail__movie">
                      {detail_movie_sample_movie_element(detail_movie)}
                      <div className="movie-detail__movie-info">
                        {/* 購入はこちら的なリンクをつける */}
                        {detail_movie_tag_element(detail_movie.iteminfo.actress,"女優名","actress")}
                        {detail_movie_tag_element(detail_movie.iteminfo.director,"ディレクター","director")}
                        {detail_movie_tag_element(detail_movie.iteminfo.genre,"ジャンル","genre")}
                        {detail_movie_tag_element(detail_movie.iteminfo.label,"レーベル","label")}
                        {detail_movie_tag_element(detail_movie.iteminfo.maker,"メーカー","maker")}
                      </div>
                      <a href={affiliate_link_digital_video_detail(detail_movie.content_id)} target="_blank"  className="button button--orange button--buy">
                        もっと動画を視聴する
                      </a>
                    </div>
                    {/* サンプル画像 */}
                      {detail_movie_sampleImageURL_sample_l_image_element(detail_movie)}
                    {/* 関連動画 */}
                      {component_detail_relation(detail_movie.iteminfo.actress)}
                  </div>
                  <div className="movie-detail__side">
                    <PickupMovieList/>
                  </div>
                </div>
              </div>
            )
          }
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </>
  );
}

