
import React,{useState,useEffect} from 'react';
import {Header} from '../../component/Header';
import {Footer} from '../../component/Footer';
import {Breadcrumb} from '../../component/Breadcrumb';
import {movie_detail_link, get_api_url_tag} from '../../utils/dmm_api';
import axios from 'axios';

export const ActressDetail1030262 = ()=>{
  let article_id = "1030262";
  let movie_list_api;
  movie_list_api = get_api_url_tag("actress",article_id,"")

  const [movie_list,set_movie_list] = useState([])
  useEffect(()=>{
    axios.get(movie_list_api)
    .then(api_result=>{
      set_movie_list(api_result.data.result.items)
    })
  },[])

  let breadcrumb_list = [{
      "name":"home",
      "link":"/",
    },{
      "name":"人気AV女優一覧",
      "link":"/actress/list",
    },
    {
      "name":"三上悠亜",
    },
]

  return (
    <>
      <Header />
      <div className="page page-actress-detail">
        <div className="page-main">
          <div className="page-content">
            <Breadcrumb BreadcrumbList={breadcrumb_list} />
            <div className="actress-detail">
              <div className="actress-detail__contents">
                <div className="actress-detail__thumbnail">
                  <img src="https://pics.dmm.co.jp/digital/video/ssis00144/ssis00144jp-2.jpg" alt="口に手を当てヒソヒソ話をしてくる三上悠亜" /> 
                </div>
                <h1 className="page-title" >
                  三上悠亜 <span>Yua Mikami</span>
                </h1>
                <p className="actress-detail__description">
                  デビューから常に最前線で活躍している三上悠亜。男性のみならず女性のファンも多い。
                  タレント活動やYouTube、アパレルブランドYOUR'S をプロデュースなど幅広く活躍中。
                  スタイルもいいが、さすがは元アイドル笑顔がとにかく素晴らしい。笑顔がとても素敵だし本当にsexを楽しんでいるなと思わせてくれる。
                  SKE48の元メンバーで、アイドルとしてのポテンシャルは恵比寿マスカッツ、HONEY POPCORNで発揮している。
                  歌、ダンス共に他のアイドルと遜色ない。SKE48時代と比べパフォーマンスが進化しているという声もあるほど。
                  AV女優としてもアイドルとしても今後のさらなる飛躍に期待大。
                </p>
                <div className="actress-detail__profile">
                  <dl className="actress-detail__definition-list">
                    <dt className="actress-detail__definition-trem">
                      生年月日
                    </dt>
                    <dd className="actress-detail__definition-description">
                      1993/8/16
                    </dd>
                    <dt className="actress-detail__definition-trem">
                      出身
                    </dt>
                    <dd className="actress-detail__definition-description">
                      愛知県
                    </dd>
                    <dt className="actress-detail__definition-trem">
                      デビュー
                    </dt>
                    <dd className="actress-detail__definition-description">
                      2015/6/1
                    </dd>
                    <dt className="actress-detail__definition-trem">
                      サイズ
                    </dt>
                    <dd className="actress-detail__definition-description">
                      B:83/W:57/H:88(cm)
                    </dd>
                    <dt className="actress-detail__definition-trem">
                      趣味
                    </dt>
                    <dd className="actress-detail__definition-description">
                      <ul>
                        <li>アイドルのDVD鑑賞</li>
                        <li>歌</li>
                        <li>ダンス</li>
                        <li>犬の散歩</li>
                        <li>ショッピング</li>
                        <li>カフェめぐり</li>
                      </ul>
                    </dd>
                    <dt className="actress-detail__definition-trem">
                      関連タグ
                    </dt>
                    <dd className="actress-detail__definition-description">
                      <div className="movie-tag">
                      
                        <ul class="movie-tag__list">
                          <li class="movie-tag__list-item" data-element-id="1027">
                            <a href="/movie/list?article=genre&amp;article_id=1027&amp;article_name=美少女">美少女</a>
                          </li>
                          <li class="movie-tag__list-item" data-element-id="1027">
                            <a href="/movie/list?article=genre&amp;article_id=1027&amp;article_name=巨乳">巨乳</a>
                          </li>
                        </ul>
                      </div>
                    </dd>
                    <dt className="actress-detail__definition-trem">
                      関連動画
                    </dt>
                    <dd className="actress-detail__definition-description">
                      <div className="movie-list">
                          {
                            movie_list.map(
                              movie_list => 
                              <div className="movie-list__item"  key={movie_list.content_id}>
                                <a href={movie_detail_link(movie_list.content_id)}>
                                  <img src={movie_list.imageURL.large} alt={movie_list.title} />
                                  <div className="movie-list__item-name">{movie_list.title}</div>
                                  <div className="movie-list__item-actress">{}</div>
                                </a>
                              </div>
                            )
                          }
                      </div>
                    </dd>
                  </dl>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </>
  );
}

