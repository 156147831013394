// import dmm from 'dmm.js';
// secret managerを使用する方針
import request from "request";
export const api_id = "RHmFA41wALP5LhnHYeaV";
export const affiliate_id = "punizyocom-001";

// Webサービスに使用する Affiliate ID
// リクエスト時に使用するアフィリエイトIDは末尾990～999以外リクエストできない制限になっております。
// リクエスト時にエラーが返る方はご確認をお願いいたします。
export const affiliate_id_web_service = "punizyocom-990";

export const api_url_new_arrival_movie = 
    `https://api.dmm.com/affiliate/v3/ItemList?
    api_id=${api_id}
    &affiliate_id=${affiliate_id_web_service}
    &site=FANZA&service=digital&floor=videoa&hits=10&sort=review&keyword=希志あいの
    &output=json`

export const get_api_url_pick_up_movie = (hits) =>{ 
    return(
        `https://api.dmm.com/affiliate/v3/ItemList?
        api_id=${api_id}
        &affiliate_id=${affiliate_id_web_service}
        &site=FANZA&service=digital&floor=videoa&hits=${hits}&sort=rank
        &output=json`
    )
}

//関連動画のapiを返す関数
//引数に女優のarrayを受け取る
export const get_api_url_relation_movie = (actress_name_list) =>{ 
   if (actress_name_list==null){
    console.log(`[get_api_url_relation_movie] actress_name_list is Null`);
    return(
        `https://api.dmm.com/affiliate/v3/ItemList?
        api_id=${api_id}
        &affiliate_id=${affiliate_id_web_service}
        &site=FANZA&service=digital&floor=videoa&hits=30&sort=review&keyword=希志あいの&keyword=羽咲みはる
        &output=json`
    )
   }else{
    //actress_name_listの0番目のもののみを表示する
    console.log(`[get_api_url_relation_movie] actress_name_list:${actress_name_list} `);
    return(
        `https://api.dmm.com/affiliate/v3/ItemList?
        api_id=${api_id}
        &affiliate_id=${affiliate_id_web_service}
        &site=FANZA&service=digital&floor=videoa&hits=30&sort=review&keyword=${actress_name_list[0]}
        &output=json`
    )
   }
}

export const get_api_url_detail_movie = (content_id)=>{
    return `https://api.dmm.com/affiliate/v3/ItemList?api_id=${api_id}&affiliate_id=${affiliate_id_web_service}&site=FANZA&service=digital&floor=videoa&hits=1&cid=${content_id}&output=json`
}

// デジタル動画 商品詳細のありフィリエイトリンクを返す関数
// digital/videoa/-/detail/
// 引数にはcontent_id (商品IDを入れる)
export const affiliate_link_digital_video_detail = (content_id) =>{
    // address = `https://al.dmm.co.jp/?lurl=https%3A%2F%2Fwww.dmm.co.jp%2Fdigital%2Fvideoa%2F-%2Fdetail%2F%3D%2Fcid%3D${content_id}%2F&af_id=${affiliate_id}&ch=search_link&ch_id=text`
    const address = `https://www.dmm.co.jp/digital/videoa/-/detail/=/cid=${content_id}/?utm_medium=dmm_affiliate&utm_source=${affiliate_id}&utm_campaign=affiliate_search_link&utm_content=text`
    return address
  }

//アフィリエイトリンクを返す
export const movie_detail_link = (content_id) => {
    return `/movie/detail/${content_id}`
}

export const get_api_url_tag = (article,article_id,article_name)=>{
  console.log(`[get_api_url_tag] article : ${article}`);
  console.log(`[get_api_url_tag] article_id : ${article_id}`);
  console.log(`[get_api_url_tag] article_name : ${article_name}`);
  //tag_keyがactressの場合
  switch(article){
        case "actress":
            console.log(`[get_api_url_tag] article is actress`);
            return (
                `https://api.dmm.com/affiliate/v3/ItemList?api_id=${api_id}&affiliate_id=${affiliate_id_web_service}&site=FANZA&service=digital&floor=videoa&hits=50&sort=rank&article=actress&article_id=${article_id}&output=json`
            )
        break;
        case "director":
            console.log(`[get_api_url_tag] article is director`);
            return (
                `https://api.dmm.com/affiliate/v3/ItemList?api_id=${api_id}&affiliate_id=${affiliate_id_web_service}&site=FANZA&service=digital&floor=videoa&hits=50&sort=date&keyword=${article_name}&output=json`
            )
        break;
        case "genre":
            console.log(`[get_api_url_tag] article is genre`);
            return (
                `https://api.dmm.com/affiliate/v3/ItemList?api_id=${api_id}&affiliate_id=${affiliate_id_web_service}&site=FANZA&service=digital&floor=videoa&hits=50&sort=date&article=genre&article_id=${article_id}&output=json`
            )
        break;
        case "label":
            console.log(`[get_api_url_tag] article is label`);
            return (
                `https://api.dmm.com/affiliate/v3/ItemList?api_id=${api_id}&affiliate_id=${affiliate_id_web_service}&site=FANZA&service=digital&floor=videoa&hits=50&sort=date&keyword=${article_name}&output=json`
            )
        break;
        case "maker":
            console.log(`[get_api_url_tag] article is maker`);
            return (
                `https://api.dmm.com/affiliate/v3/ItemList?api_id=${api_id}&affiliate_id=${affiliate_id_web_service}&site=FANZA&service=digital&floor=videoa&hits=50&sort=date&article=maker&article_id=${article_id}&output=json`
            )
  }



}