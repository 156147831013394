import React from 'react';
import { Header } from '../../component/Header';
import { Footer } from '../../component/Footer';
import { Sidebar } from '../../component/Sidebar';
import { Breadcrumb } from '../../component/Breadcrumb';
import { affiliate_link_digital_video_detail } from '../../utils/dmm_api';

export const BlogArticle2 = ()=>{
  const affiliate_link = affiliate_link_digital_video_detail("ssni00378");
  const breadcrumb_list = [{
      "name":"home",
      "link":"/",
    },
    {
      "name":"エスワン15周年スペシャル大共演 第3弾 超豪華S1女優大集合 素人チ●ポをヌキまくりハメまくり夢の大乱交！ ファン大感謝祭ツアー の感想",
    },
]
  return (
    <>
      <Header />
      <div className="page page-blog-article">
        <div className="page-main">
          <Sidebar />
          <div className="page-content">
            <Breadcrumb BreadcrumbList={breadcrumb_list} />
            <h1 className="page-title">エスワン15周年スペシャル大共演 第3弾 超豪華S1女優大集合 素人チ●ポをヌキまくりハメまくり夢の大乱交！ ファン大感謝祭ツアー の感想</h1>
              <div className="blog-article">
                <div className="blog-article__time">
                  <time>2021.08.11</time>
                </div>
                <div className="blog-article__main-visual">
                  <img src="/img/top/blog_thumbnail2.png" alt="エスワン15周年スペシャル大共演 第3弾 超豪華S1女優大集合 素人チ●ポをヌキまくりハメまくり夢の大乱交！ ファン大感謝祭ツアー の感想サムネイル"/>
                </div>
                <div className="blog-article__box">
                    <p className="blog-article__beginning">三上悠亜、あやみ旬果、葵つかさ、吉高寧々、橋本ありな、 羽咲みはる、 天使もえ<br />という誰もが知るビックネームの女優が勢揃いした奇跡の作品です。</p>
                    <p>リンク<br /><a href={affiliate_link} target="_blank">エスワン15周年スペシャル大共演 第3弾 超豪華S1女優大集合 素人チ●ポをヌキまくりハメまくり夢の大乱交！ ファン大感謝祭ツアー の感想</a></p>
                    <h2>全員主人公</h2>
                    <p>
                      多対多系の作品の場合一部の女優にピックアップされがちで、推しの女優があんまり活躍しないなんてこともしばしば、、、<br />ですがこの作品は、出演されている女優が全員がピックアップされており、出演した女優全員が活躍していました!<br />全員が主人公という感じで全員可愛くてエロくてね、もうサイコー!!! という状態でした。
                    </p>
                    <h2>まとめ</h2>
                    <p>2,3年前にリリースされましたが褪せることのない素晴らしい作品だと思っています。<br />
                    エスワンさんありがとうございます!!!!<br />
                    感謝感謝!!!</p>
                  <a href={affiliate_link} target="_blank"  className="button button--orange button--buy ">
                    動画を視聴する
                  </a>
                  <div className="blog-article__image_list">
                    <div className="blog-article__image_list-item">
                      <img src="https://pics.dmm.co.jp/digital/video/ssni00378/ssni00378jp-1.jpg" alt="バスで記念撮影をする三上悠亜、あやみ旬果、葵つかさ、吉高寧々、橋本ありな、 羽咲みはる、 天使もえ"/>
                    </div>
                    <div className="blog-article__image_list-item">
                      <img src="https://pics.dmm.co.jp/digital/video/ssni00378/ssni00378jp-2.jpg" alt="おっぱいで男性器を挟む羽咲みはる"/>
                    </div>
                    <div className="blog-article__image_list-item">
                      <img src="https://pics.dmm.co.jp/digital/video/ssni00378/ssni00378jp-3.jpg" alt="おっぱいに精子をかけられるあやみ旬果と天使もえ"/>
                    </div>
                    <div className="blog-article__image_list-item">
                      <img src="https://pics.dmm.co.jp/digital/video/ssni00378/ssni00378jp-4.jpg" alt="プールサイドで騎乗位をするあやみ旬果と"/>
                    </div>
                    <div className="blog-article__image_list-item">
                      <img src="https://pics.dmm.co.jp/digital/video/ssni00378/ssni00378jp-5.jpg" alt="深夜の体育館で睡眠している青年にエロいことをしようとしている葵つかさと橋本ありな"/>
                    </div>
                    <div className="blog-article__image_list-item">
                      <img src="https://pics.dmm.co.jp/digital/video/ssni00378/ssni00378jp-6.jpg" alt="ブルマ姿で騎乗位をしている三上悠亜と男性のパンツを下ろす羽咲みはる" />
                    </div>
                    <div className="blog-article__image_list-item">
                      <img src="https://pics.dmm.co.jp/digital/video/ssni00378/ssni00378jp-7.jpg" alt="体育館でバックされる橋本ありなと葵つかさと吉高寧々"/>
                    </div>
                    <div className="blog-article__image_list-item">
                      <img src="https://pics.dmm.co.jp/digital/video/ssni00378/ssni00378jp-8.jpg" alt="正常位されているおっぱいが出ている三上悠亜とそれをみている天使もえとあやみ旬果"/>
                    </div>
                    <div className="blog-article__image_list-item">
                      <img src="https://pics.dmm.co.jp/digital/video/ssni00378/ssni00378jp-9.jpg" alt="大乱交"/>
                    </div>
                    <div className="blog-article__image_list-item">
                      <img src="https://pics.dmm.co.jp/digital/video/ssni00378/ssni00378jp-10.jpg" alt="白い下着を身につける三上悠亜、あやみ旬果、葵つかさ、吉高寧々、橋本ありな、 羽咲みはる、 天使もえ"/>
                    </div>
                  </div>
                  <a href={affiliate_link} target="_blank"  className="button button--orange button--buy">
                    動画を視聴する
                  </a>
                </div>
              </div>
          </div>
        </div>

      </div>
      <Footer/>
    </>
  );
}

